<script>
import GizmoComponentMixin from "~/mixins/GizmoComponentMixin.js";
import LinkViewModel from "~/helpers/ViewModels/LinkViewModel.js";

export default defineNuxtComponent({
	name: "GizmoButton",
	mixins: [GizmoComponentMixin],

	computed: {
		buttons() {
			return this.content.buttons.map((button) => {
				return {
					...button,
					link: LinkViewModel(button.link),
				};
			});
		},
		size() {
			return (this.$store.getters["isMobile"] && "s") || "m";
		},
	},
});
</script>

<template>
	<div class="buttons">
		<TnButton
			v-for="(button, idx) in buttons"
			v-bind="button.link"
			:icon-left="button.icon?.left"
			:icon-right="button.icon?.right"
			:primary="button.type === 'primary'"
			:secondary="button.type === 'secondary'"
			:dark="dark"
			class="button"
			:size="size"
			:key="idx"
		>
		</TnButton>
	</div>
</template>

<style scoped lang="scss">
.buttons {
	display: flex;
	flex-wrap: wrap;
	gap: $spacing-m $spacing-m;
}
</style>
